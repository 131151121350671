<template>
    <div class="box" id="ygh-content-box">
        <div class="big">
            <div class="big_title">
                <!-- <a-button type="primary" @click="$_loading.show('正在下载...')"> 批量下载表单 </a-button> -->
                <h3>文书归档</h3>
                <span>将企业内所有的文件分别归类放置，以便于日后相关人员查询和使用，不担心文件丢失，永久保存。</span>
            </div>
            <div class="box-btns">
                <a-button
                    :type="btck == i + 1 ? 'primary' : ''"
                    icon="file-sync"
                    class="btns"
                    v-for="(o, i) in btna"
                    :key="i + 1"
                    @click="change_status(i + 1)"
                    >{{ o }}</a-button
                >
            </div>
            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    >
                        <span slot="operator">
                            <div class="operator_content">
                                <a-form layout="inline">
                                    <a-form-item>
                                        <a-input
                                            style="min-width: 180px; width: auto"
                                            v-model="queryParam.name"
                                            placeholder="请输入关键字"
                                            @search="onSearch"
                                            class="search"
                                        >
                                        </a-input>
                                    </a-form-item>
                                    <a-form-item label="密级程度">
                                        <a-select
                                            v-model="queryParam.secrecyDegree"
                                            style="min-width: 200px; width: auto"
                                            placeholder="请选择"
                                            allowClear
                                        >
                                            <a-select-option
                                                v-for="(item, index) in flow_secrecy_degree"
                                                :key="index"
                                                :value="item.code"
                                                >{{ item.value }}</a-select-option
                                            >
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item label="时间">
                                        <a-range-picker
                                            allowClear
                                            v-model="queryParam.archivedTime"
                                            style="min-width: 200px; width: auto"
                                        />
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button type="primary" @click="$refs.table.refresh(true)"> 查询 </a-button>
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button
                                            :loading="loading"
                                            :disabled="!selectedRowKeys || selectedRowKeys.length === 0"
                                            type="primary"
                                            @click="downloadMany"
                                        >
                                            批量下载表单
                                        </a-button>
                                        <a-button
                                            :loading="loadingFile"
                                            :disabled="!selectedRowKeys || selectedRowKeys.length === 0"
                                            type="primary"
                                            @click="downloadManyFile"
                                            style="margin-left: 5px"
                                        >
                                            批量下载文件
                                        </a-button>
                                    </a-form-item>
                                </a-form>
                            </div>
                        </span>
                        <span slot="archivedUser" slot-scope="text, record">
                            <div v-for="(o, i) in record.archivedUser" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="sendIds" slot-scope="text, record">
                            <div v-for="(o, i) in record.sendIds" :key="i" class="my-item">
                                <a-tag class="tags flex0" color="blue">
                                    <OpenData type="userName" :openid="o.userId" :corpid="o.corpId"></OpenData>
                                </a-tag>
                            </div>
                        </span>
                        <span slot="documentType" slot-scope="text">
                            <span v-if="text == +1">收文登记</span>
                            <span v-else-if="text == +2">快速发文</span>
                            <span v-else-if="text == +3">拟稿发文</span>
                        </span>
                        <span slot="acceptTime" slot-scope="text">
                            {{ text && text.substr(0, 10) }}
                        </span>
                        <span slot="urgencyDegree" slot-scope="text" :style="flowUrgencyDegree(text)">
                            {{ Filter(flow_urgency_degree, text) }}
                        </span>
                        <span slot="secrecyDegree" slot-scope="text" :style="flowSecrecyDegree(text)">
                            {{ Filter(flow_secrecy_degree, text) }}
                        </span>
                        <span slot="approvalStatus" slot-scope="text" :class="text != 2 ? 'fred' : ''">
                            {{ Filter(flow_approval_status, text) }}
                        </span>
                        <span slot="action" slot-scope="text, record">
                            <!-- <a class="bts" @click="download(record)">下载</a> -->
                            <a-dropdown>
                                <a class="ant-dropdown-link"> 下载 <a-icon type="down" /> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a @click="download(record)">下载表单</a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a @click="downloadFile(record)">下载文件</a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <a style="margin-left: 5px" class="bts" @click="see(record)">详情</a>
                        </span>
                        <span slot="docTitle" slot-scope="text">
                            {{ text ? (text.length > 23 ? `${text.slice(0, 23)}...` : text) : '/' }}
                        </span>
                    </s-table>
                </a-card>
            </div>
        </div>
        <detail ref="detailInfo" />
    </div>
</template>
  
<script>
// eslint-disable-next-line camelcase
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { documentArchivedList, wxRealFile, wxTransform } from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import moment from 'moment'
import { receivedColumns, sendingColumns } from './columns.js'
import detail from './detail.vue'
import { handleFile } from './minxins.js'

export default {
    name: 'list',
    mixins: [stateColor, handleFile],
    components: {
        STable,
        OpenData,
        detail,
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['收文', '发文'],
            btck: 1,
            loading: false,
            loadingFile: false,
            // 查询参数
            //queryParam: { approvalStatus: 0, documentType: 1 },
            queryParam: {
                archivedType: 1,
            },
            // 表头
            columns: receivedColumns,
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                const { archivedTime } = this.queryParam
                if (archivedTime) {
                    this.queryParam.startArchivedTime = archivedTime[0] && moment(archivedTime[0]).format('YYYY-MM-DD')
                    this.queryParam.endArchivedTime = archivedTime[1] && moment(archivedTime[1]).format('YYYY-MM-DD')
                    delete this.queryParam.archivedTime
                }
                this.selectedRowKeys = []
                this.selectedRows = []
                return documentArchivedList(Object.assign(parameter, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    if (data.rows) {
                        data.rows = data.rows.map((ele) => {
                            ele.archivedUser = formatDraftId(ele.archivedUser)
                            return ele
                        })
                    }
                    handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool', '.big_title'], 110, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    watch: {
        '$route.query.index': {
            handler() {
                this.changeTabStatus(Number(this.$route.query.index) || 1)
            },
            immediate: true,
        },
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.box-btns', '.s-table-tool', '.big_title'], 110)
    },
    methods: {
        handleOk() {},
        wxTransform(str) {
            this.$_loading.show('正在下载文件...')
            wxTransform({
                ids: str,
            })
                .then((res) => {
                    if (res.code === 200 && res.data) {
                        this.createNodeUploadFile(res.data)
                    }
                })
                .finally(() => {
                    this.loading = false
                    this.$_loading.hide()
                })
        },
        wxRealFile(ids) {
            this.$_loading.show('正在下载文件...')
            wxRealFile({
                ids,
            })
                .then((res) => {
                    if (res) {
                        this.handleFileFlow(ids)
                    }
                })
                .finally(() => {
                    this.loadingFile = false
                    this.$_loading.hide()
                })
        },
        download({ billId }) {
            this.wxTransform(billId)
        },
        downloadMany() {
            if (this.selectedRows.length > 20) return this.$message.warning('选择的数据不能超过20条')
            if (this.loading) return
            this.loading = true
            this.wxTransform(this.selectedRows.map((j) => j.billId).join())
        },
        downloadFile({ billId }) {
            this.wxRealFile(billId)
        },
        downloadManyFile() {
            if (this.selectedRows.length > 20) return this.$message.warning('选择的数据不能超过20条')
            if (this.loadingFile) return
            this.loadingFile = true
            this.wxRealFile(this.selectedRows.map((j) => j.billId).join())
        },
        createNodeUploadFile(url, fileName = 'fileName') {
            var downloadElement = document.createElement('a')
            downloadElement.style.display = 'none'
            downloadElement.href = url
            downloadElement.download = fileName
            document.body.appendChild(downloadElement)
            downloadElement.click() // 点击下载
            document.body.removeChild(downloadElement) // 下载完成移除元素
            this.spinning = false
        },
        refresh() {
            this.$refs.table.refresh()
        },
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 获取字典数据
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        changeTabStatus(i) {
            this.queryParam = {}
            this.btck = i
            this.queryParam.archivedType = i
            if (+i == 1) {
                this.columns = receivedColumns
            } else {
                this.columns = sendingColumns
            }
            this.$refs.table && this.$refs.table.refresh(true)
        },
        change_status(i) {
            if (this.btck === i) return
            this.$router.push({
                query: {
                    index: i,
                },
            })

            // this.$refs.table.refresh(true)
        },
        see(record) {
            this.$refs.detailInfo.open({ ...record, type: record.documentType })
        },
        // 处理文件流
        handleFileFlow(ids) {
            try {
                let url = `${process.env.VUE_APP_API_BASE_URL}/wx/getRealFile?ids=${ids}`
                // let name = headers['content-disposition']
                // // let type = headers['content-type']
                // let fileName = this.getFileName(name)
                // let contentType = this.getFileContentTypeByFileName(fileName)
                // let blob = new Blob([resData], { type: `${contentType};charset=UTF-8` })
                // // 通过URL.createObjectURL生成文件路径
                // let url = window.URL.createObjectURL(blob)
                this.createNodeUploadFile(url)
                window.URL.revokeObjectURL(url)
            } catch (error) {
                alert(error.message)
            }
        },
        getFileName(str) {
            if (!str) return
            const arr = str.split(';')
            // filename*=
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            let temp = arr.find((e) => filenameRegex.exec(e))
            if (temp) {
                let fileArr = temp.split('=')
                return fileArr[1].replace(/\"/g, '')
            }
            // filename=
            temp = arr.find((e) => e.startsWith('filename='))
            if (temp) {
                return temp.replace('filename=', '')
            }
            throw Error('未在响应头获取到文件名（请检查响应头字段大小写），且默认自定义文件名未传！！')
        },
    },
}
</script>
  
<style lang="less" scoped>
.box {
    width: 1280px;
    height: calc(100% - 17px - 24px);
    margin: auto !important;
    background: #ffffff;
    border-radius: 4px;

    padding: 33px 39px;
    position: relative;

    .big {
        button {
            font-size: 16px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }

    .search {
        width: 300px;
        border-radius: 4px;
    }
}

.operator_content {
    margin: 20px 0 10px;
}

::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}

::v-deep .ant-card-body {
    padding: 0;
}

.btns {
    margin-right: 17px;
}

.fred {
    color: #f56c6c;
}

.bts {
    margin-right: 10px;
}

.big_title {
    margin: 5px 0 20px;

    h3 {
        color: #000000;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }

    span {
        color: #9d9d9d;
        font-size: 14px;
    }
}

.operatorContent {
    margin: 0 !important;
    justify-content: space-between;
    width: 100%;
    height: 47px;
    align-items: flex-end;
}

.flex0 {
    display: flex;
    align-items: center;
}

.my-item {
    display: inline-block;

    .tags {
        margin-bottom: 1px;
    }
}
</style>
  