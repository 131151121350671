<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <div>
      <a-row :gutter="10">
        <a-col :span="16" :push="4">
          <receTable :formObj="formMessage" v-if="type == 1"></receTable>
          <sendTable :formObj="formMessage" v-if="type == 2"></sendTable>
          <billTable :formObj="formMessage" v-if="type == 3"></billTable>
        </a-col>
      </a-row>
    </div>
  </full-model-view>
</template>
  
  <script>
import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { FullModelView } from '@/components'
import billTable from './components/billTable.vue'
import receTable from './components/receTable.vue'
import sendTable from './components/sendTable.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    sendTable,
    receTable,
    billTable,
  },
  data() {
    return {
      title: '详情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      formMessage: {
        name: '发文稿纸',
      },
    }
  },
  watch: {
    officialId: {
      handler(val) {
        val && this.getInfo()
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, billId }) {
      this.officialId = billId
      this.visible = true
      this.type = type
    },
    getInfo() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          const { data, success, message } = res
          if (!success || !data) {
            this.$message.error(message || '请求失败，请联系相关业务员')
            this.close()
            return
          }
          const {
            name,
            acceptTime,
            urgencyDegree,
            secrecyDegree,
            deviseOpinion,
            draftId,
            // approvalWay,
            tellPhone,
            summary,
            title,
            documentNodeList,
            documentFileList, //附件
            documentNumber,
            mainFile, //正文
            checkIds,
            draftCompany,
            outDraftDesc,
            outDraftCompany,
            meetCompany,
            meetDesc,
            templateId,
            approvalStatus,
            serialNumber,
            receiverCompany,
            id,
          } = res.data
          const form = {
            name,
            meetDesc,
            meetCompany,
            outDraftDesc,
            outDraftCompany,
            lwsj: acceptTime,
            jjcd: urgencyDegree ? String(urgencyDegree) : '',
            miji: secrecyDegree ? String(secrecyDegree) : '',
            nbyj: deviseOpinion,
            deviseOpinion,
            draftId,
            checkIds,
            draftCompany,
            title,
            nodes: documentNodeList,
            documentNodeList,
            tellPhone,
            attach: mainFile && [mainFile], //正文
            mainFile,
            fileIds: documentFileList, //附件
            summary,
            documentNumber,
            templateId,
            serialNumber,
            receiverCompany,
            id,
          }
          // console.log('formMessage+++++++++++', form)
          this.approvalStatus = approvalStatus
          //
          this.formMessage = form
          //
          this.fileIds = documentFileList
        })
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    },
  },
}
</script>
  
  <style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}
.add-content-box {
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}
.add-content-block {
  margin: 0 auto;
}
.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
  